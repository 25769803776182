import React from 'react';
import Layout from "@/locales/en/layout/Layout";
import CustomTitle from "@/components/custom_title";
// import {useTranslation} from "react-i18next";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import {useQuery} from "react-query";
// import {getGreenUniversitet} from "@/api/general";

import img1_1 from "./img/1.1.jpg";
import img1_2 from "./img/1.2.jpg";
import img1_3 from "./img/1.3.jpg";
import img1_4 from "./img/1.4.jpg";
import img1_5 from "./img/1.5.jpg";
import img1_6 from "./img/1.6.jpg";
import img2_1 from "./img/2.1.jpg";
import img2_2 from "./img/2.2.jpg";
import img2_3 from "./img/2.3.jpg";

function GreenUniversitet(props) {
    // const {t} = useTranslation();
    // const {data} = useQuery("greenUniverPost", getGreenUniversitet);

    document.title = "Yashil universitet";
    const dateAllGreen = [
        {
            title: "TDTUda “Yashil makon” umummilliy loyihasi doirasida ko‘chat ekish aksiyasi o‘tkazildi",
            text:"<p>🌿 Islom Karimov nomidagi Toshkent davlat texnika universitetida mamlakatimizda ekologik vaziyatni yaxshilash, yashil maydonlarni kengaytirishga hissa qo&lsquo;shish maqsadida &ldquo;Yashil makon&rdquo; umummilliy loyihasi doirasida ko&lsquo;chat ekish aksiyasi o&lsquo;tkazildi.&nbsp;</p> <p>🌿 Davlatimiz rahbari tomonidan ilgari surilgan &ldquo;Yashil makon&rdquo; umummilliy loyihasi doirasida tashkil etilgan aksiyada universitet rahbariyati, professor-o&lsquo;qituvchilar, fakultet talabalari, ishchi-xodimlar, tyutorlar hamda talaba-yoshlar ishtirok etdi.</p>",
            img: [
                {
                    original: img1_1,
                    thumbnail: img1_1,

                },
                {
                    original: img1_2,
                    thumbnail: img1_2,

                },
                {
                    original: img1_3,
                    thumbnail: img1_3,

                },
                {
                    original: img1_4,
                    thumbnail: img1_4,

                },
                {
                    original: img1_5,
                    thumbnail: img1_5,

                },
                {
                    original: img1_6,
                    thumbnail: img1_6,

                },
            ],
        },
        {
            title: "TDTU rektori akademik Sadritdin Turabdjanov \"Yashil makon\" loyihasida faol ishtirok etdi",
            text:"<p>✅\"Yashil makon\" umummilliy loyihasi doirasida oliy taʼlim muassasalarida \"Ziyolilar bog&lsquo;\"lari tashkil etilishi munosabati bilan Jizzax politexnika institutida ko&lsquo;chat ekish marafoniga start berildi.<br>✅Oliy taʼlim, fan va innovatsiyalar vazirligi tashabbusi bilan tashkil etilgan ushbu tadbirda Jizzax viloyati hokimi o&lsquo;rinbosari Akmal Savurboyev, Oliy taʼlim, fan va innovatsiyalar vaziri Qo&lsquo;ng&lsquo;irotboy Sharipov boshchiligidagi vazirlik mutasaddilari, hududiy boshqarmalar va koʻplab oliy taʼlim muassasalari rektorlari ishtirok etdi.<br>✅Tadbirda Islom Karimov nomidagi Toshkent davlat texnika universiteti rektori, akademik Sadritdin Turabdjanov ham faol tashabbus koʻrsatdi. Akademik Sadritdin Turabdjanov yashil maydonlarni koʻpaytirish, ekologik muhitni yaxshilash va yosh avlodni tabiatga mehr ruhida tarbiyalashning muhimligini atrofidagi kelgan mutasaddilariga taʼkidladi.<br>✅\"Ziyolilar bogʻi\"da koʻchat ekish marafonida TDTU rektori boshchiligidagi jamoa ham faol ishtirok etib, hudud obodonchiligiga oʻz hissasini qoʻshdi. Akademik Sadritdin Turabdjanov bu kabi loyihalar yoshlarning ekologik madaniyatini oshirish, atrof-muhitga masʼuliyatli munosabatda boʻlishga undashini taʼkidladi.<br>✅\"Yashil makon\" loyihasi doirasida TDTUda ham keng koʻlamli ishlar amalga oshirilishi rejalashtirilgan.</p>",
            img: [
                {
                    original: img2_1,
                    thumbnail: img2_1,

                },
                {
                    original: img2_2,
                    thumbnail: img2_2,

                },
                {
                    original: img2_3,
                    thumbnail: img2_3,

                },
            ],
        },

    ];
    return (
        <Layout>
            <CustomTitle name={"Yashil universitet"}/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="card" style={{width: "18 rem"}}>
                            <iframe width="100%" height="300" src="https://www.youtube.com/embed/5kwbqip4zYk"
                                    title="TDTUda “Yashil makon” umummilliy loyihasi doirasida ko‘chat ekish aksiyasi o‘tkazildi"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            <div className="card-body">
                                <b>TDTUda “Yashil makon” umummilliy loyihasi doirasida ko‘chat ekish aksiyasi
                                    o‘tkazildi</b>
                                <p className="card-text mt-2">
                                    Islom Karimov nomidagi Toshkent davlat texnika universitetida mamlakatimizda
                                    ekologik vaziyatni yaxshilash, yashil maydonlarni kengaytirishga hissa qo‘shish
                                    maqsadida “Yashil makon” umummilliy loyihasi doirasida ko‘chat ekish aksiyasidan
                                    video lavha!!!
                                </p>
                            </div>
                        </div>
                    </div>
                    {
                        dateAllGreen.map((item,index) => (
                                <div className="col-sm-4" key={index}>
                                    <div className="card" style={{width: "18 rem"}}>
                                        <ImageGallery items={item?.img}/>
                                        <div className="card-body">
                                            <b>{item?.title}</b>
                                            <p className="card-text mt-2" dangerouslySetInnerHTML={{__html: item?.text}}/>
                                        </div>
                                    </div>
                                </div>
                        ))
                    }

                </div>
            </div>
        </Layout>
    );
}

export default GreenUniversitet;